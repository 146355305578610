import { Injectable } from "@angular/core";
import { CONSTANTS } from "../common/constants";

@Injectable()
export class LocalStorageHelper {
    USER_KEY = 'user_info';
    LOGIN_INFO = 'login_info';
    TOKEN = 'x-wash-website';

    constructor() {
    }

    setLoginInfo(value: any) {
        localStorage.setItem(this.LOGIN_INFO, JSON.stringify(value));
    }

    getLoginInfo() {
        const data = localStorage.getItem(this.LOGIN_INFO);
        return data ? JSON.parse(data) : null;
    }

    setUserInfo(user: any) {
        localStorage.setItem(this.USER_KEY, JSON.stringify(user));
    }

    getUserInfo() {
        const user = localStorage.getItem(this.USER_KEY);
        return user ? JSON.parse(user) : null;
    }

    setToken(token: string) {
        localStorage.setItem(this.TOKEN, token);
    }

    getToken() {
        return localStorage.getItem(this.TOKEN)
    }

    removeToken() {
        localStorage.removeItem(this.TOKEN);
    }

    clear() {
        localStorage.clear();
        return true;
    }

    checkUserLoginExpiried() {
        const loginInfo = this.getLoginInfo()
        if (loginInfo && (new Date().getTime() - loginInfo.loginAt >= CONSTANTS.ONE_DAY_IN_MILLISECONDS)) {
            this.clear()
        }
        return true
    }
}