import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private toastrService: ToastrService
  ) { }

  showSuccess(message: string = 'Success!') {
    this.toastrService.clear();
    this.toastrService.success(message);
  }

  showError(message: string = 'Error!') {
    this.toastrService.clear();
    this.toastrService.error(message);
  }

  showWarning(message: string = 'Warning!') {
    this.toastrService.clear();
    this.toastrService.warning(message);
  }
}
