import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LoadingService } from '../../services/loading.service';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-dialog-navigate',
  templateUrl: './dialog-navigate.component.html',
  styleUrls: ['./dialog-navigate.component.scss']
})
export class DialogNavigateComponent {
  lat: any
  lng: any
  constructor(
    private loadingService: LoadingService,
    private utilService: UtilsService,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef) {
    const data = config.data
    this.lat = data.lat
    this.lng = data.lng
  }

  navigateGoogle() {
    this.loadingService.show()
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        try {
          this.loadingService.hide()

          let newWin = window.open(`http://maps.google.com/maps?saddr=${position.coords.latitude},${position.coords.longitude}&daddr=${this.lat},${this.lng}`, '_blank');

          if (!newWin || newWin.closed || typeof newWin.closed == undefined) {
            alert('Popup Blocker is enable. Please allow popups for this website!');
          }
        } catch (error: any) {
          console.log('error', error);
          this.loadingService.hide()
          return this.utilService.showError(error.message);
        }
      },
      (error) => {
        this.loadingService.hide()
        return this.utilService.showError(error.message);
      }
    );
  }

  navigateWaze() {
    window.open(`https://waze.com/ul?ll=${this.lat},${this.lng}&navigate=yes&zoom=17`, '_blank');
  }
}
