import { Component, Input, OnInit } from '@angular/core';
import { CONSTANTS } from '../../common/constants';
import { MenuItem } from 'primeng/api';
@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {
  STEPS: any = CONSTANTS.STEPS_BOOKING
  @Input() currentStep: any = CONSTANTS.STEPS_BOOKING.BOOK
  @Input() items: MenuItem[] | undefined
  constructor() {

  }

  ngOnInit(): void {
    if (!this.items) {
      this.items = [
        {
          label: 'BOOK',
          styleClass: this.currentStep >= this.STEPS.BOOK ? 'active-step' : ''
        },
        {
          label: 'WASH',
          styleClass: this.currentStep >= this.STEPS.WASH ? 'active-step' : ''
        },
        {
          label: 'GO!',
          styleClass: this.currentStep == this.STEPS.GO ? 'active-step' : ''
        }
      ];
    }
  }
}
