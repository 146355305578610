import { Component } from '@angular/core';
import { Router } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'gocar-x-wash-website';
  constructor(public router: Router) {
  }

  async ngOnInit() { }
}
