import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class WashMachineService extends BaseApiService {
  prefixURL = 'public/website/bookings';
  constructor(public override http: HttpClient) {
    super(http);
  }

  startWashing(params: { bookingId: string, machineId: string }): Promise<any> {
    return this.post(`${this.prefixURL}/start-washing`, params);
  }
}
