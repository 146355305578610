import { Component } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-dialog-selection',
  templateUrl: './dialog-selection.component.html',
  styleUrls: ['./dialog-selection.component.scss']
})
export class DialogSelectionComponent {
  valueField: any
  descriptionField: any
  items: any = []
  constructor(public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    this.items = config.data.items
    this.valueField = config.data.valueField
    this.descriptionField = config.data.descriptionField
    console.log(config.data);
  }

  onSelected(item: any) {
    this.ref.close(item)
  }
}
