import { Component, OnInit } from '@angular/core';
import { LocalStorageHelper } from '../../helpers/local-storage.helper';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  userLogin: User = {}
  constructor(private localStorage: LocalStorageHelper, private authService: AuthService) {
    this.localStorage.checkUserLoginExpiried()
    this.userLogin = this.localStorage.getUserInfo()
    this.authService.userLogin.subscribe(user => {
      this.userLogin = user
    })
  }
}
