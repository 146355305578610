<div id="wrapper" *ngIf="router.url.indexOf('/wash-machine') === -1">
  <app-header></app-header>

  <div id="page-wrapper" class="position-relative">
    <div class="bg-header"></div>
    <div class="content position-absolute">
      <div class="row justify-content-center" [ngClass]="{'mb': router.url.indexOf('/home') === -1}">
        <div class="col-12 col-md-4">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
  <app-loading></app-loading>
</div>
<app-footer *ngIf="router.url.indexOf('/home') === -1 && router.url.indexOf('/wash-machine') === -1"></app-footer>
<div id="wrapper" *ngIf="router.url.indexOf('/wash-machine') > -1">
  <router-outlet></router-outlet>
  <app-loading></app-loading>
</div>
