<ul class="px-0">
    <li class="d-flex justify-content-between align-items-center py-2 clickable" (click)="navigateWaze()">
        <div>
            <img src="/assets/imgs/icon-waze.jpg" alt="Icon Waze">
            <span class="ms-3">Waze</span>
        </div>
        <i class="fa fa-arrow-right"></i>
    </li>
    <li class="d-flex justify-content-between align-items-center py-2 clickable" (click)="navigateGoogle()">
        <div>
            <img src="/assets/imgs/icon-google-map.jpg" alt="Icon Maps">
            <span class="ms-3">Google Map</span>
        </div>
        <i class="fa fa-arrow-right"></i>
    </li>
</ul>