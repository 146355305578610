import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  _showLoading = new EventEmitter<any>();

  constructor() { }

  public show(duration?: number) {
    this._showLoading.emit({ state: true, duration: duration });
  }

  public hide() {
    this._showLoading.emit({ state: false });
  }
}
