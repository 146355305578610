<div class="header">
    <div class="d-flex align-items-end justify-content-between p-3 content">
        <div class="logo clickable" routerLink="/">
            <img class="total" src="/assets/imgs/logo-total.jpg" alt="Total Logo">
            <img class="wash" src="/assets/imgs/logo-wash.jpg" alt="Wash Logo">
        </div>
        <div class="logo-gocar">
            <span class="fs-10">Powered by</span>
            <img src="/assets/imgs/logo-gocar.png" alt="GoCar Logo">
        </div>
    </div>
</div>