import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent {
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig) { }

  ok() {
    this.ref.close({ action: 'yes' })
  }
  cancel() {
    this.ref.close({ action: 'no' })
  }
}
