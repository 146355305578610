import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Vehicle } from '../models/vehicle';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleService extends BaseApiService {
  prefixURL = 'protected/website/vehicles';
  constructor(public override http: HttpClient) {
    super(http);
  }

  getAllVehicle(): Promise<any> {
    return this.get(`${this.prefixURL}/all`);
  }

  createVehicle(body: Vehicle): Promise<any> {
    return this.post(`${this.prefixURL}`, body);
  }

  updateVehicle(id: string, body: Vehicle): Promise<any> {
    return this.put(`${this.prefixURL}/${id}`, body);
  }
  
  deleteVehicle(id: string): Promise<any> {
    return this.delete(`${this.prefixURL}/${id}`);
  }
}
