import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseApiService {
  prefixURL = 'protected/website/users';
  constructor(public override http: HttpClient) {
    super(http);
  }

  updateUser(user: User): Promise<any> {
    return this.put(`${this.prefixURL}/profile`, user);
  }
}
