import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepsComponent } from './components/steps/steps.component';
import { StepsModule } from 'primeng/steps';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { UtilsService } from './services/utils.service';
import { DialogSelectionComponent } from './components/dialog-selection/dialog-selection.component';
import { AuthService } from './services/auth.service';
import { LocalStorageHelper } from './helpers/local-storage.helper';
import { ProfileService } from './services/profile.service';
import { VehicleService } from './services/vehicle.service';
import { ConfigService } from './services/config.service';
import { DialogConfirmComponent } from './components/dialog-confirm/dialog-confirm.component';
import { PaymentMethodComponent } from './components/payment-method/payment-method.component';
import { DialogNavigateComponent } from './components/dialog-navigate/dialog-navigate.component';
import { BookingService } from './services/booking.service';
import { WashMachineService } from './services/wash-machine.service';
@NgModule({
  declarations: [
    StepsComponent,
    DialogSelectionComponent,
    DialogConfirmComponent,
    PaymentMethodComponent,
    DialogNavigateComponent
  ],
  imports: [
    CommonModule,
    StepsModule,
    DynamicDialogModule
  ],
  providers: [
    DialogService,
    MessageService,
    UtilsService,
    LocalStorageHelper,
    ProfileService,
    VehicleService,
    ConfigService,
    BookingService,
    WashMachineService
  ],
  exports: [
    StepsComponent,
    DialogSelectionComponent,
    DialogConfirmComponent,
    PaymentMethodComponent,
    DialogNavigateComponent
  ]
})
export class SharedModule { }
