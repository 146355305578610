import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PaymentMethod } from '../../models/payment_method';
import { BookingService } from '../../services/booking.service';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {

  paymentMethods: PaymentMethod[] = []
  eWaletMethods: PaymentMethod[] = []
  onlineBankingMethods: PaymentMethod[] = []
  creditCards: PaymentMethod[] = [
    {
      paymentId: 2,
      name: "Credit Card / Debit Card"
    }
  ]
  currentMethod: 'online-banking' | 'e-wallet' | 'credit-card' | undefined
  currentPaymentId: any
  @Output() onSelectPayment = new EventEmitter<any>();
  constructor(private bookingService: BookingService) {

  }

  ngOnInit(): void {
    this.getPaymentMethod()
  }

  async getPaymentMethod() {
    try {
      const rs = await this.bookingService.getPaymentMethod()
      this.paymentMethods = rs && rs.data
      this.onlineBankingMethods = this.paymentMethods.filter(p => p.type == 'online-banking')
      this.eWaletMethods = this.paymentMethods.filter(p => p.type == 'e-wallet')
    } catch (error: any) {
      console.log(error && error.error && error.error.message);
    }
  }

  onselectPaymentMethod(item: PaymentMethod) {
    this.currentPaymentId = item.paymentId
    this.onSelectPayment.emit(item)
  }

}
