import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService extends BaseApiService {
  prefixURL = 'public/website/config';
  constructor(public override http: HttpClient) {
    super(http);
  }

  getAll(): Promise<any> {
    return this.get(`${this.prefixURL}`);
  }
}
