import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment as config } from '../../../environments/environment'
import { LocalStorageHelper } from '../helpers/local-storage.helper';

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {
  apiURL = config.API_URL;

  constructor(protected http: HttpClient) { }

  private createHttpOptions(params?: any, headersPairs?: any) {
    let myHeaders = {
      'Content-Type': 'application/json',
    } as any;
    if (headersPairs) {
      Object.keys(headersPairs).forEach(key => {
        myHeaders[key] = headersPairs[key];
        if (key === 'Content-Type' && !headersPairs[key]) {
          delete myHeaders[key];
        }
      });
    }

    const token = localStorage.getItem('x-wash-website')
    if (token) {
      myHeaders['x-wash-website'] = token
    }
    let headers = new HttpHeaders(myHeaders);
    return { headers, params };
  }

  get(path: string, params?: any): Promise<any> {
    return firstValueFrom(this.http
      .get<any>(`${this.apiURL}/${path}`, this.createHttpOptions(params)));
  }

  post(path: string, body: any, headersPairs?: any): Promise<any> {
    return firstValueFrom(this.http
      .post<any>(`${this.apiURL}/${path}`, body, this.createHttpOptions(null, headersPairs)));
  }

  put(path: string, body: any, headersPairs?: any): Promise<any> {
    return firstValueFrom(this.http
      .put<any>(`${this.apiURL}/${path}`, body, this.createHttpOptions(null, headersPairs)));
  }

  patch(path: string, body: any): Promise<any> {
    return firstValueFrom(this.http
      .patch<any>(`${this.apiURL}/${path}`, body, this.createHttpOptions()));
  }

  delete(path: string): Promise<any> {
    return firstValueFrom(this.http
      .delete<any>(`${this.apiURL}/${path}`, this.createHttpOptions()));
  }
}