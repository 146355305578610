
export const CONSTANTS = {
  ERROR_MESSAGE: {
    INVALID_URL: 'Invalid URL path!'
  },
  UNKNOW_ERROR: 'Opps, something went wrong, please try again later.',
  ONE_DAY_IN_MILLISECONDS: 1 * 24 * 60 * 60 * 1000,

  STATUS_CODE: {
    OK: 200,
    UNAUTHORIZED: 401,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500
  },

  STEPS_BOOKING: {
    BOOK: 0,
    WASH: 1,
    GO: 2
  },
  STEPS_VEHICLE: {
    TYPES: 0,
    PACKAGE: 1,
    PAYMENT: 2
  },
  VEHICLE_SIZE: {
    SEDANS: { value: 'sedans', name: 'Sedans', order: 1, imgDefault: '/assets/imgs/img-sedan.png', imgSelected: '/assets/imgs/img-sedan-selected.png' },
    MPV_SUV_CROSSOVER: { value: 'mpv_suv_crossover', name: 'MPV, SUV, Crossover', order: 2, imgDefault: '/assets/imgs/img-suv.png', imgSelected: '/assets/imgs/img-suv-selected.png' },
    PICK_UP_TRUCKS_VANS: { value: 'pick_up_trucks_vans', name: 'Pick-up Trucks, Vans', order: 3, imgDefault: '/assets/imgs/img-vans.png', imgSelected: '/assets/imgs/img-vans-selected.png' },
  },
  SEARCH_CONDITION: {
    LIMIT: 25,
    PAGE: 1,
    SORT_FIELD: 'created',
    SORT_TYPE: 'desc'
  },
  BOOKING_STATUS: {
      PENDING: { value: 'pending', name: 'Active' },
      COMPLETED: { value: 'completed', name: 'Used' },
  }
};
