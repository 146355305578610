import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class BookingService extends BaseApiService {
  prefixURL = 'protected/website/bookings';
  constructor(public override http: HttpClient) {
    super(http);
  }

  getPaymentMethod(): Promise<any> {
    return this.get(`${this.prefixURL}/get-payment-method`);
  }

  generateHoldPayment(params: any): Promise<any> {
    return this.post(`${this.prefixURL}/generate-hold-payment`, params);
  }

  getBookings(params: any): Promise<any> {
    return this.get(`${this.prefixURL}/get-bookings`, params);
  }

  getBookingById(id: any): Promise<any> {
    return this.get(`${this.prefixURL}/${id}`);
  }
}
