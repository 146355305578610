<div class="text-center">
    <div class="white-block mb-3 p-4 home">
        <h2 class="fs-24 mb-3">
            Wash your car the clean, affordable and eco-responsible way
        </h2>

        <p class="text-blur my-3">
            Our rollover machine features soft anti-scratch brushes, eco-friendly and safe detergents, ensuring that
            your
            vehicles maintain their glow and colors.
        </p>

        <app-steps [currentStep]="STEPS.GO"></app-steps>

        <div class="img-home mt-4">
            <img src="/assets/imgs/img-car-wash.png" alt="Car Wash Banner">
        </div>

    </div>
    <button class="btn btn-standazd w-100" routerLink="/booking/step-station">
        Start Using Wash
    </button>
</div>