<div class="row justify-content-center footer py-2">
    <div class="col-12 col-md-4">
        <div class="d-flex align-items-center justify-content-around">
            <div class="text-center clickable" routerLink="/">
                <i class="fa fa-home d-block"></i>
                <span class="fs-10">Home</span>
            </div>
            <div class="text-center clickable" routerLink="/my-wash">
                <i class="fa-solid fa-qrcode d-block"></i>
                <span class="fs-10">My Wash</span>
            </div>
            <div class="text-center clickable" routerLink="/profile">
                <i class="fa fa-user d-block"></i>
                <span class="fs-10">Profile</span>
            </div>
        </div>
    </div>
</div>