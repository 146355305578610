<section *ngIf="step == 'phone'">
    <div class="white-block p-3">
        <div class="mt-3">
            <h2 class="fs-24">
                Enter your mobile number to Login
            </h2>
            <p class="text-blur">
                OTP will be send to this mobile number
            </p>

            <div class="mt-5 mb-2 input-box">
                <span class="prefix">+60</span>
                <input type="number" [(ngModel)]="phoneNumber" class="input-standazd" maxlength="14">
            </div>
        </div>
    </div>

    <div class="mt-3">
        <button class="btn btn-standazd w-100" (click)="requestOTP()">Continue</button>
    </div>
</section>

<section *ngIf="step == 'otp'">
    <div class="white-block p-3">
        <div class="mt-3">
            <h2 class="fs-24">
                Enter 6-digit OTP
            </h2>
            <p class="text-blur">
                For verification, OTP was sent to +60{{phoneNumber}}
            </p>

            <div class="mt-5 mb-2 d-flex justify-content-between">
                <input type="number" maxlength="1" class="otp-number" [(ngModel)]="otp1"
                    oninput="this.value=this.value.slice(0, this.maxLength)" (keyup)="validate($event)">
                <input type="number" maxlength="1" class="otp-number" [(ngModel)]="otp2"
                    oninput="this.value=this.value.slice(0, this.maxLength)" (keyup)="validate($event)">
                <input type="number" maxlength="1" class="otp-number" [(ngModel)]="otp3"
                    oninput="this.value=this.value.slice(0, this.maxLength)" (keyup)="validate($event)">
                <input type="number" maxlength="1" class="otp-number" [(ngModel)]="otp4"
                    oninput="this.value=this.value.slice(0, this.maxLength)" (keyup)="validate($event)">
                <input type="number" maxlength="1" class="otp-number" [(ngModel)]="otp5"
                    oninput="this.value=this.value.slice(0, this.maxLength)" (keyup)="validate($event)">
                <input type="number" maxlength="1" class="otp-number" [(ngModel)]="otp6"
                    oninput="this.value=this.value.slice(0, this.maxLength)" (keyup)="validate($event)">
            </div>
            <div class="my-3">
                <strong class="link text-underline fs-14" (click)="requestOTP()">Request for a new OTP</strong>
            </div>
        </div>
    </div>

    <div class="mt-3">
        <button class="btn btn-standazd w-100" (click)="submitOTP()">Continue</button>
    </div>
</section>

<section *ngIf="step == 'profile'">
    <div class="white-block p-3">
        <div class="mt-3">
            <h2 class="fs-24">
                Update Profile
            </h2>
            <p class="text-blur">
                Enter your details securely to complete your order
            </p>
        </div>

        <div class="mt-3">
            <strong class="text-blur">Full Name</strong>
            <div class="input-box">
                <input class="input-standazd" type="text" [(ngModel)]="userLogin.fullName"
                    placeholder="Enter full name">
            </div>
        </div>
        <div class="mt-3">
            <strong class="text-blur">Phone Number</strong>
            <div class="input-box">
                <input class="input-standazd" type="text" value="{{'+60' + phoneNumber}}" placeholder="Enter full name"
                    disabled>
                <span class="suffix"><i class="fa fa-lock"></i></span>
            </div>
        </div>
        <div class="mt-3">
            <strong class="text-blur">Email Address</strong>
            <div class="input-box">
                <input class="input-standazd" type="text" [(ngModel)]="userLogin.email"
                    placeholder="Enter email address">
            </div>
        </div>
    </div>
    <div class="mt-3">
        <button class="btn btn-standazd w-100" (click)="updateProfile()">Continue</button>
    </div>
</section>