<ul class="px-0">
    <li class="d-flex justify-content-between align-items-center py-3 clickable" *ngFor="let item of items"
        (click)="onSelected(item)">
        <span>{{item[descriptionField]}}</span>
        <i class="fa fa-arrow-right"></i>
    </li>
</ul>

<div class="text-center" *ngIf="!items || !items.length">
    No Item Available
</div>