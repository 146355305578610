import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { CONSTANTS } from '../common/constants';
import { LocalStorageHelper } from '../helpers/local-storage.helper';
import { NavService } from '../services/nav.service';

@Injectable()
export class AuthLogin implements CanActivate {

    constructor(
        private router: Router,
        private localStorageHelper: LocalStorageHelper,
        private navService: NavService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        try {
            const loginInfo = this.localStorageHelper.getLoginInfo();
            const userLoggedIn = this.localStorageHelper.getUserInfo();
            const navURL = state.url

            // if (state.url.includes('/login')) {
            //     if (userLoggedIn) {
            //         this.router.navigate(['/home']);
            //         return false
            //     }
            //     return true
            // }

            if (!loginInfo || !loginInfo.loginAt || !userLoggedIn || !userLoggedIn.id) {
                this.navService.navigate('/login', { navURL })
                return false;
            }

            if (new Date().getTime() - loginInfo.loginAt < CONSTANTS.ONE_DAY_IN_MILLISECONDS) {
                return true;
            } else {
                this.localStorageHelper.clear()
            }

            this.navService.navigate('/login', { navURL })
            return false;
        } catch (error) {
            this.router.navigate(['/home']);
            return false;
        }
    }
}
