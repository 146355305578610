import { Component } from '@angular/core';
import { CONSTANTS } from 'src/app/shared/common/constants';
import { LoadingService } from 'src/app/shared/services/loading.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  STEPS = CONSTANTS.STEPS_BOOKING
  constructor(private load: LoadingService) {
    // load.show()
  }
}
