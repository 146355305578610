import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthLogin } from './shared/auth/auth.login';
import { APP_ROUTES } from './shared/common/app-routes';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: APP_ROUTES.HOME,
    component: HomeComponent,
    canActivate: []
  },
  {
    path: APP_ROUTES.LOGIN,
    component: LoginComponent,
    canActivate: []
  },
  {
    path: APP_ROUTES.BOOKING, loadChildren: () => import(`./pages/booking/booking.module`).then(m => m.BookingModule),
    canActivate: []
  },
  {
    path: APP_ROUTES.PROFILE, loadChildren: () => import(`./pages/profile/profile.module`).then(m => m.ProfileModule),
    canActivate: [AuthLogin]
  },
  {
    path: APP_ROUTES.MY_WASH, loadChildren: () => import(`./pages/my-wash/my-wash.module`).then(m => m.MyWashModule),
    canActivate: [AuthLogin]
  },
  {
    path: APP_ROUTES.WASH_MACHINE, loadChildren: () => import(`./pages/wash-machine/wash-machine.module`).then(m => m.WashMachineModule),
    canActivate: []
  },
  {
    path: '**',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
