<div class="gray-block p-3" [ngClass]="{'gray-block-selected': currentMethod == 'e-wallet'}"
    (click)="currentMethod = 'e-wallet'">
    <i class="fa fa-wallet me-3"></i>
    <span>E-wallet</span>
    <ul class="px-0" *ngIf="currentMethod == 'e-wallet'">
        <li class="d-flex justify-content-between align-items-center py-3" *ngFor="let item of eWaletMethods"
            (click)="onselectPaymentMethod(item)">
            <div>
                <img *ngIf="item.paymentId == 523" src="/assets/imgs/icon-grab.png" alt="Icon Grab">
                <img *ngIf="item.paymentId == 538" src="/assets/imgs/icon-tng.png" alt="Icon Touch N GO">
                <span class="ms-3">{{item.name}}</span>
            </div>
            <i class="fa fa-circle-check" *ngIf="item.paymentId == currentPaymentId"></i>
        </li>
    </ul>
</div>

<div class="gray-block p-3 mt-2" [ngClass]="{'gray-block-selected': currentMethod == 'online-banking'}"
    (click)="currentMethod = 'online-banking'">
    <i class="fa fa-bank me-3"></i>
    <span>Online Banking</span>
    <ul class="px-0" *ngIf="currentMethod == 'online-banking'">
        <li class="d-flex justify-content-between align-items-center py-3" *ngFor="let item of onlineBankingMethods"
            (click)="onselectPaymentMethod(item)">
            <div>
                <span>{{item.name}}</span>
            </div>
            <i class="fa fa-circle-check" *ngIf="item.paymentId == currentPaymentId"></i>
        </li>
    </ul>
</div>
<div class="gray-block p-3 mt-2" [ngClass]="{'gray-block-selected': currentMethod == 'credit-card'}"
    (click)="currentMethod = 'credit-card'">
    <i class="fa fa-credit-card me-3"></i>
    <span>Credit / Debit Card</span>
    <ul class="px-0" *ngIf="currentMethod == 'credit-card'">
        <li class="d-flex justify-content-between align-items-center py-3" *ngFor="let item of creditCards"
            (click)="onselectPaymentMethod(item)">
            <div>
                <span>{{item.name}}</span>
            </div>
            <i class="fa fa-circle-check" *ngIf="item.paymentId == currentPaymentId"></i>
        </li>
    </ul>
</div>