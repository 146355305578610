import { Component } from '@angular/core';
import { LocalStorageHelper } from 'src/app/shared/helpers/local-storage.helper';
import { User } from 'src/app/shared/models/user';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { NavService } from 'src/app/shared/services/nav.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  step: 'phone' | 'otp' | 'profile' = 'phone'
  phoneNumber: any = ''
  otp1: any; otp2: any; otp3: any; otp4: any; otp5: any; otp6: any
  userLogin: User = {}
  dataNavService: { navURL: string } | undefined
  constructor(private navService: NavService,
    private utilService: UtilsService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private localStorage: LocalStorageHelper,
    private profileService: ProfileService) {
    this.dataNavService = this.navService.data
    const user = this.localStorage.getUserInfo()
    if (user) {
      this.back()
    }
  }

  async requestOTP() {
    if (!this.phoneNumber) {
      this.utilService.showWarning('Please input Your Mobile Number!')
      return
    }
    this.loadingService.show()
    try {
      const phoneNo = '+60' + this.phoneNumber
      await this.authService.requestOTP(phoneNo)
      this.step = 'otp'
      this.utilService.showSuccess('OTP has been sent to your Phone Number +60' + this.phoneNumber)
    } catch (error: any) {
      this.utilService.showError(error && error.error && error.error.message)
    } finally {
      this.loadingService.hide()
    }
  }

  async submitOTP() {
    try {
      const otpNumber = `${this.otp1}${this.otp2}${this.otp3}${this.otp4}${this.otp5}${this.otp6}`
      if (!this.phoneNumber || !otpNumber) {
        return
      }
      this.loadingService.show()
      const phoneNo = '+60' + this.phoneNumber
      const rs = await this.authService.verifyOTP(phoneNo, otpNumber)
      this.userLogin = rs && rs.data
      this.authService.handleLoginSuccess(this.userLogin)
      if (!this.userLogin.fullName) {
        this.step = 'profile'
      } else {
        this.back()
      }
    } catch (error: any) {
      this.utilService.showError(error && error.error && error.error.message)
    } finally {
      this.loadingService.hide()
    }
  }

  async updateProfile() {
    if (!this.userLogin.fullName) {
      this.utilService.showError('Please update your fullname!')
      return
    }
    if (!this.userLogin.email) {
      this.utilService.showError('Please update your email!')
      return
    }
    this.loadingService.show()
    try {
      await this.profileService.updateUser(this.userLogin)
      this.authService.handleLoginSuccess(this.userLogin)
      this.back()
    } catch (error: any) {
      this.utilService.showError(error && error.error && error.error.message)
    } finally {
      this.loadingService.hide()
    }
  }

  back() {
    if (this.dataNavService && this.dataNavService.navURL) {
      this.navService.navigate(this.dataNavService.navURL)
    } else {
      this.navService.back()
    }
  }

  validate(event: any) {
    if (Number(event.keyCode) >= 48 && Number(event.keyCode) <= 57) {
      this.focusNextInput(event);
      return;
    }

    if (event.keyCode == '8') {
      this.focusPrevInput(event);
    }
  }

  private focusNextInput(event: any) {
    const element = event.srcElement.nextElementSibling;
    if (element == null) {
      return;
    }

    element.focus();
  }

  focusPrevInput(event: any) {
    const element = event.srcElement.previousElementSibling;
    if (element == null) {
      return;
    }

    element.focus();
  }
}
