import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageHelper } from '../helpers/local-storage.helper';
import { BaseApiService } from './base-api.service';
import { environment as config } from 'src/environments/environment'
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseApiService {
  prefix = 'public/website/auth'
  private userLoginSubject = new BehaviorSubject<any>({});
  public userLogin: Observable<any>;
  constructor(
    public override http: HttpClient,
    private localStorage: LocalStorageHelper,
    private router: Router) {
    super(http);
    this.apiURL = config.API_URL
    const data = this.localStorage.getUserInfo()
    this.userLoginSubject = new BehaviorSubject<any>(data);
    this.userLogin = this.userLoginSubject.asObservable();
  }

  logout() {
    this.localStorage.clear()
    this.router.navigate(['/login'])
  }

  handleLoginSuccess(userInfo: any): boolean {
    this.localStorage.setLoginInfo({ loginAt: new Date().getTime() });
    this.localStorage.setUserInfo(userInfo);
    this.localStorage.setToken(userInfo.token);
    this.userLoginSubject.next(userInfo);
    return true;
  }

  requestOTP(phoneNumber: string) {
    return this.post(`${this.prefix}/request-otp`, { phoneNumber });
  }

  verifyOTP(phoneNumber: string, otpNumber: string) {
    const headerParams = { 'gocar-authentication': phoneNumber + ':' + otpNumber }
    return this.post(`${this.prefix}/verify-otp`, {}, headerParams);
  }
}
